<template>
    <div class="ynap-footer">
        Copyright 2024 ® - All rights reserved
    </div>
</template>
<script>
export default {
    name: "YNAPFooter"
};
</script>
<style scoped>
.ynap-footer {
  padding: 0.75em;
  font-size: .75em;
  background: #074992;
  color: white;
  min-height: 2em;
  text-align: right;
  border-top: 1px solid #4b4b4b;
}
</style>
