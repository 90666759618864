<template>
  <template v-if="isUserInfoLoaded">
    <PrivateDashboard v-if="user" />
    <PublicView v-else />
  </template>
</template>

<script>
import { userInfoStore } from './stores/user-info-store';
import PrivateDashboard from './components/PrivateDashboard.vue';
import PublicView from './components/PublicView.vue';

export default {
  name: 'App',
  components: {
    PrivateDashboard,
    PublicView
  },
  computed: {
    user() {
      return userInfoStore.user;
    },
    isUserInfoLoaded() {
      return userInfoStore.isInitializing == false;
    }
  },
  watch: {
    $route(to, from) {
      console.log('App: Route changed', to, from);
    }
  }
}
</script>

<style>
#app {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
