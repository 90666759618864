// This module is a store for the accounts data. 
// It should provide a reactive list of budget-related properties, as well as some functions to update the value of these properties.

import { reactive } from 'vue';
import router from '../router';
import { API_URL } from '../server/server-proxy';

export let budgetsStore = reactive({
    budgets: null,
    isLoading: true
});

export function setSelectedBudget(budget) {
    if (budget) {
        router.push({ name: 'budget-overview', params: { id: budget.id } });
    } else {
        router.push({ name: 'budgets' });
    }
}

export const fetchBudgets = async () => {
    console.log('Fetching budgets from server...');
    const budgets = await fetch(API_URL + '/budgets')
        .then(response => response.json())
        .then(data => {
            console.log('Budgets fetched:', data);
            budgetsStore.budgets = data.data.budgets;
            budgetsStore.isLoading = false;
        })
        .catch(error => {
            console.error('There was an error!', error);
        });
}