<template>
    <div class="ynap-private-dashboard-sidebar" :class="{ 'ynap-private-dashboard-sidebar--expanded': isExpanded }"
        style="display: flex; flex-direction: column;">
        <div style="flex: auto;">
            <ul v-for="group in groups" class="list-group mb-2" :key="group.id">
                <RouterLink v-for="item in group.items" class="list-group-item list-group-item-action" :key="item.route"
                    :to="item.route" :class="{ active: $route.path == item.route }" data-bs-toggle="tooltip"
                    data-bs-placement="right" :data-bs-title="item.name" @click="linkClicked">
                    <i :class="'fa-solid ' + item.icon"></i> <span v-if="isExpanded">{{ item.name }}</span>
                </RouterLink>
            </ul>
        </div>
        <div style="flex: none">
            <hr>
            <div class="ynap-private-dashboard-sidebar__footer-buttons">
                <button @click="signOut" class="btn btn-danger ynap-private-dashboard-sidebar__logout-button" title="Log Out">
                    <i class="fa-solid fa-sign-out"></i>
                </button>
                <button @click="toggleSidebar" class="btn btn-dark ynap-private-dashboard-sidebar__toggle-menu" title="Toggle sidebar">
                    <i class="fa-solid fa-fw fa-rotate-180" :class="{ 'fa-caret-right': !isExpanded, 'fa-caret-left': isExpanded }"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { userInfoStore, signOut } from '../stores/user-info-store';
import { Tooltip } from 'bootstrap';

export default {
    name: 'PrivateDashboardSideBar',
    data() {
        return {
            groups: [
                {
                    id: 'top', 
                    items: [
                        { name: 'Home', icon: 'fa-home', route: '/' }
                    ]
                },
                {
                    id: 'main', 
                    items: [
                        { name: 'Budgets', icon: 'fa-money-bill', route: '/budgets' },
                        { name: 'Contacts', icon: 'fa-users', route: '/contacts' },
                        { name: 'Categories', icon: 'fa-rectangle-list', route: '/categories' },
                        { name: 'Reports', icon: 'fa-chart-bar', route: '/reports' },
                        { name: 'Account', icon: 'fa-circle-user', route: '/account' },
                        { name: 'Settings', icon: 'fa-cog', route: '/settings' }
                    ]
                },
                {
                    id: 'other', 
                    items: [
                        { name: 'Refer a Friend', icon: 'fa-person-circle-plus', route: '/refer-a-friend' },
                        { name: 'Privacy Policy', icon: 'fa-file-text', route: '/privacy-policy' },
                        { name: 'Terms & Conditions', icon: 'fa-gavel', route: '/terms-and-conditions' }
                    ]
                }
            ],
            isExpanded: true,
            tooltips: []
        };
    },
    created() {
        console.log("PrivateDashboardSideBar: created");
    },
    mounted() {
        console.log("PrivateDashboardSideBar: mounted");
        let tooltipTriggers = this.$el.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltipTriggers.forEach((trigger) => {
            let newTooltip = new Tooltip(trigger, {
                delay: { show: 0, hide: 0 },
                trigger: 'hover'
            });
            if (this.isExpanded) {
                newTooltip.disable();
            }
            this.tooltips.push(newTooltip);
        });
    },
    beforeUnmount() {
        this.tooltips.forEach((tooltip) => {
            console.log("disposing tooltip");
            tooltip.dispose(); // Clean up
        });
    },
    methods: {
        signOut() {
            signOut();
        },
        toggleSidebar() {
            this.isExpanded = !this.isExpanded;
        },
        linkClicked(event) {
            let elem = event.currentTarget;
            if (elem) {
                let tooltip = Tooltip.getInstance(elem);
                if (tooltip) {
                    tooltip.hide();
                }
            }
        }
    },
    computed: {
        $user() {
            return userInfoStore.user;
        }
    },
    watch: {
        isExpanded(newVal) {
            this.tooltips.forEach((tooltip) => {
                if (newVal) {
                    tooltip.disable();
                } else {
                    tooltip.enable();
                }
            });
        }
    }
}
</script>

<style scoped>
.ynap-private-dashboard-sidebar {
    padding: 1em;
    width: 77px;
    border-right: 1px solid #c0c0c0;
    background: #20b2aa;
    height: 100%;
    overflow-y: auto;
    flex: none;
    transition: width 0.3s;

    .ynap-private-dashboard-sidebar__logout-button {
        width: 100%;
        margin-bottom: 0.5em;
    }

    .ynap-private-dashboard-sidebar__toggle-menu {
        width: 100%;
    }


    &.ynap-private-dashboard-sidebar--expanded {
        width: 260px;

        .list-group-item {

            i.fa-solid {
                margin-right: 1em;
            }
        }

        .ynap-private-dashboard-sidebar__footer-buttons {
            display: flex;
            align-items: center;
            justify-content: center;

            .ynap-private-dashboard-sidebar__logout-button {
                width: 100%;
                flex: auto;
                margin-right: 0.5em;
                margin-bottom: 0;
            }

            .ynap-private-dashboard-sidebar__toggle-menu {
                width: auto;
                flex: none;
            }

        }
    }

    .list-group-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        height: 42px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        i.fa-solid {
            width: 14px;
            height: 14px;
            display: inline-block;
            text-align: center;
        }
    }
}

img {
    width: 1em;
    margin-right: 1em;
    border: 1px solid #757575
}
</style>