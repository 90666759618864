import { reactive } from 'vue';
import { auth } from '../firebase-app';
import router from '../router';
import {
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithPopup,
    signOut as signOutFirebase
} from 'firebase/auth';

onAuthStateChanged(auth, user => {
    userInfoStore.isInitializing = false;
    userInfoStore.user = user;
    if (user) {
        console.log("Logged in!");
    } else {
        if (router.currentRoute.pathname !== "/") {
            router.push("/");
        }
        console.log("No user");
    }
});

export const userInfoStore = reactive({
    isInitializing: true,
    user: null
});

export const signIn = async () => {
    await signInWithPopup(auth, new GoogleAuthProvider());
};

export const signOut = async () => {
    await signOutFirebase(auth);
};