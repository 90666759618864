<template>
  <div class="ynap-private-dashboard">
    <div class="ynap-private-dashboard--inner">
      <div class="ynap-private-dashboard-sidebar-container">
        <PrivateDashboardSidebar />
      </div>
      <div class="ynap-private-dashboard-content">
        <RouterView />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { userInfoStore } from '../stores/user-info-store';
import { budgetsStore, fetchBudgets } from '../stores/budgets-store';
import Footer from './Footer.vue';

import PrivateDashboardSidebar from './PrivateDashboardSidebar.vue';

export default {
  name: 'PrivateDashboard',
  mounted() {
    fetchBudgets();
  },
  components: {
    Footer,
    PrivateDashboardSidebar
  },
  data() {
    return {
      transactions: [],
      accounts: []
    };
  },
  methods: {
    // async getTransactions() {
    //   const transactionsCol = collection(db, 'transactions');
    //   const transactionSnapshot = await getDocs(transactionsCol);
    //   this.transactions = transactionSnapshot.docs.map(doc => doc.data());
    // }
  },
  computed: {
    user() {
      return userInfoStore.user;
    },
    budgets() {
      return budgetsStore.budgets;
    }
  }
}

</script>

<style scoped>

.ynap-private-dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ynap-private-dashboard--inner {
  flex: auto;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;

  .ynap-private-dashboard-content {
    flex: auto;
    overflow: auto;
  }
}
</style>
