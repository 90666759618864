<template>
    <div class="ynap-public-view fully-centered">
        <div>
            <h1>Welcome to YNAP</h1>
            <p>Please <a style="text-decoration: none;" @click="signIn" class="">Log In</a> to continue</p>
        </div>
    </div>
</template>

<script>
import { signIn } from '../stores/user-info-store';

export default {
    name: 'PublicView',
    methods: {
        signIn
    }
}

</script>

<style scoped>
.ynap-public-view {
    background: #f8f9fa;
    height: 100%;
    text-align: center;
}
</style>