import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../components/Home.vue')
    },
    {
        path: '/budgets',
        name: 'budgets',
        component: () => import('../components/SelectBudget.vue')
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: () => import('../components/Contacts.vue')
    },
    {
        path: '/categories',
        name: 'categories',
        component: () => import('../components/Categories.vue')
    },
    {
        path: '/lending',
        name: 'lending',
        component: () => import('../components/Lending.vue')
    },
    {
        path: '/reports',
        name: 'reports',
        component: () => import('../components/Reports.vue')
    },
    {
        path: '/account',
        name: 'account',
        component: () => import('../components/UserAccountInfo.vue')
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('../components/Settings.vue')
    },
    {
        path: '/refer-a-friend',
        name: 'refer-a-friend',
        component: () => import('../components/ReferFriend.vue')
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('../components/PrivacyPolicy.vue')
    },
    {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: () => import('../components/TermsAndConditions.vue')
    },
    {
        path: '/b/:id',
        name: 'budget-view',
        component: () => import('../components/BudgetView.vue'),
        children: [
            {
                path: '', // /b/:id
                name: 'budget-overview',
                component: () => import('../components/BudgetOverview.vue')
            },
            {
                path: 'transactions', // /b/:id/transactions
                name: 'budget-transactions',
                component: () => import('../components/BudgetTransactions.vue')
            },
            {
                path: 'reports', // /b/:id/reports
                name: 'budget-reports',
                component: () => import('../components/BudgetReports.vue')
            },
            {
                path: 'lending', // /b/:id/lending
                name: 'budget-lending',
                component: () => import('../components/BudgetLending.vue')
            },
            {
                path: 'settings', // /b/:id/settings
                name: 'budget-settings',
                component: () => import('../components/BudgetSettings.vue')
            },
            {
                path: 'accounts/:accountId', // /b/:id/accounts/:accountId
                name: 'budget-account-view',
                component: () => import('../components/BudgetAccount.vue')
            }
        ]
    },
    // Add an entry to catch all unknown routes and redirect to the dashboard
    {
        path: '/:catchAll(.*)',
        redirect: { name: 'home' }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;