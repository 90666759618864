
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, doc, getDocs } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCLYW3Wt5koTLj6YC03f8GPSH0D8BG0NIQ",
    authDomain: "ynab-api-proxy.firebaseapp.com",
    projectId: "ynab-api-proxy",
    storageBucket: "ynab-api-proxy.appspot.com",
    messagingSenderId: "71337094066",
    appId: "1:71337094066:web:17707383af273a233592ab",
    measurementId: "G-XNJ4771V1S"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);