import { createApp } from 'vue'
import App from './App.vue'
// import bootstrap from 'bootstrap';
// import 'shepherd.js/dist/css/shepherd.css';
import router from './router';

import './scss/styles.scss';

import './firebase-app'

createApp(App).use(router).mount('#app')